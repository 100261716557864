import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Category.css'; // Import CSS file for styling
 

const CategoryMob = () => {
    const [categories, setCategories] = useState([]);
    const [expandedCategory, setExpandedCategory] = useState(null);
    const location = useLocation();

    useEffect(() => {
        fetch('https://www.arunnathaniblog.com/wp-json/custom/v1/categories')
          .then(response => response.json())
          .then(data => {
            if (Array.isArray(data)) {
              setCategories(data);
            } else {
              console.error('Fetched data is not an array:', data);
            }
          })
          .catch(error => console.error('Error fetching categories:', error));
      }, []);
     
     
      useEffect(() => {
        // Expand category if a subcategory is active
        const pathParts = location.pathname.split('/');
        if (pathParts[1] === 'category' && pathParts.length > 2) {
          const activeCategory = categories.find(category =>
            category.slug === pathParts[2] ||
            (category.subcategories && category.subcategories.some(subcategory => subcategory.slug === pathParts[3]))
          );
          if (activeCategory) {
            setExpandedCategory(activeCategory.id);
          }
        }
      }, [location.pathname, categories]);
 
      const toggleCategory = categoryId => {
        setExpandedCategory(prevCategoryId => (prevCategoryId === categoryId ? null : categoryId));
      };
     
const handleLinkClick = () => {
    setExpandedCategory(null);
    // Trigger click on navbar toggle button
    const navbarToggleButton = document.querySelector('.navbar-toggle'); // Adjust selector to match your toggle button
    if (navbarToggleButton) {
      navbarToggleButton.click();
    }
  };

  return (

    <div>
         <section className="col-sm-12 accordionMenu hidden-xs">
        <ul className="category-list" id="menu">
          {categories.map(category => (
             category.name === 'Style' ? null : (
            <li key={category.id} className={expandedCategory === category.id ? 'active' : ''}>
              <span
                className={`category-name ${expandedCategory === category.id ? 'expanded' : ''}`}
                onClick={() => toggleCategory(category.id)}
              >
               <Link to={`/category/${category.slug === 'all-2' ? 'all' : category.slug}`}>{category.name}</Link>
                <span className="expand-icon">
                  {expandedCategory === category.id ? <i className="fa fa-angle-down"></i> : <i className="fa fa-angle-right"></i>}
                </span>
              </span>
              {expandedCategory === category.id && category.subcategories && (
                <ul className="subcategory-list">
                  {category.subcategories.map(subcategory => (
                    (subcategory.posts_count > 0 ? 
                     
                    <li key={subcategory.id} className={`subcategory ${location.pathname.includes(subcategory.slug) ? 'active' : ''}`}>
                      <Link to={`/category/${category.slug}/${subcategory.slug}`} onClick={handleLinkClick}>{subcategory.name}</Link>
                    </li>  : null )
                  ))}
                </ul>
              )}
            </li>
             )
          ))}
        </ul>
      </section>
    </div>
  );
};

export default CategoryMob;
