import React, { useState, useEffect, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import './CommentForm.css';

function formatDate(dateStr) {
  const date = new Date(dateStr);
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return date.toLocaleDateString('en-US', options);
}

function Comment({ comment, onReply }) {
  return (
    <li key={comment.id} className="comment">
      <article>
        <div className="comment-author">
          <img
            loading="lazy"
            alt=""
            src={`https://secure.gravatar.com/avatar/${comment.author_avatar_urls[96]}?s=32&d=mm&r=g`}
            className="avatar"
            height="32"
            width="32"
          />
        </div>
        <div className="comment-body">
          <div className="comment-metadata">
            <cite>{comment.author_name}</cite> <span>wrote:</span> <span>{formatDate(comment.date)}</span>
            <br></br>
            {comment.moderation && (
              <em className="comment-moderation">Your comment is awaiting moderation.</em>
            )}
          </div>
          <div className="comment-content" dangerouslySetInnerHTML={{ __html: comment.content.rendered }} />
          <div className="reply">
            <a
              className="comment-reply-link"
              href="#respond"
              onClick={(e) => {
                e.preventDefault();
                onReply(comment.id, comment.author_name);
              }}
            >
              Reply
            </a>
          </div>
        </div>
      </article>
      {comment.replies && comment.replies.length > 0 && (
        <ul className="comment-replies">
          {comment.replies.map(reply => (
            <Comment key={reply.id} comment={reply} onReply={onReply} />
          ))}
        </ul>
      )}
    </li>
  );
}

function CommentForm({ postId, onSubmit }) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [comment, setComment] = useState('');
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [commentsList, setCommentsList] = useState([]);
  const [showComments, setShowComments] = useState(true);
  const [replyTo, setReplyTo] = useState(null);
  const [replyToName, setReplyToName] = useState('');
  const [errors, setErrors] = useState({});
  const [postSlug, setPostSlug] = useState('');
  const commentToggleRef = useRef(null);

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await fetch(`https://www.arunnathaniblog.com/wp-json/wp/v2/comments?post=${postId}&per_page=100&order=asc`);
        if (!response.ok) {
          throw new Error('Failed to fetch comments');
        }
        const commentsData = await response.json();

        // Transform comments into a nested structure
        const commentsById = commentsData.reduce((acc, comment) => {
          acc[comment.id] = { ...comment, replies: [] };
          return acc;
        }, {});

        const nestedComments = [];
        commentsData.forEach(comment => {
          if (comment.parent) {
            const parentComment = commentsById[comment.parent];
            if (parentComment) {
              parentComment.replies.push(commentsById[comment.id]);
            }
          } else {
            nestedComments.push(commentsById[comment.id]);
          }
        });

        setCommentsList(nestedComments);
      } catch (error) {
        console.error('Error fetching comments: ', error);
      }
    };

    const fetchPostData = async () => {
      try {
        const response = await fetch(`https://www.arunnathaniblog.com/wp-json/wp/v2/posts/${postId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch post data');
        }
        const postData = await response.json();
        setPostSlug(postData.slug);
      } catch (error) {
        console.error('Error fetching post data: ', error);
      }
    };

    fetchComments();
    fetchPostData();
  }, [postId]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};

    // Perform validation
    if (name.trim() === '') {
      newErrors.name = 'Please enter your name.';
    }

    if (email.trim() === '') {
      newErrors.email = 'Please enter email address.';
    } else if (!email.includes('.', email.indexOf('@'))) {
      newErrors.email = 'Please enter a valid email.';
    } else if (!email.match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)) {
      newErrors.email = 'Please enter a valid email address.';
    }

    if (comment.trim() === '') {
      newErrors.comment = 'Comment box cannot be empty!';
    }

    if (!recaptchaToken) {
      newErrors.recaptcha = 'Please complete the CAPTCHA before submitting your comment.';
    }

    // If there are no errors, submit the form
    if (Object.keys(newErrors).length === 0) {
      const newComment = { name, email, comment };

      try {
        const response = await fetch(`https://www.arunnathaniblog.com/wp-json/wp/v2/comments`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            post: postId,
            author_name: name,
            author_email: email,
            content: comment,
            'g-recaptcha-response': recaptchaToken,
            parent: replyTo || 0 // Include the parent comment ID
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to submit comment');
        }

        const responseData = await response.json();
        responseData.moderation = true;
        // Update commentsList with the new comment in the correct place
        setCommentsList(prevList => {
          const addToNestedList = (list, newComment) => {
            if (newComment.parent === 0) {
              return [...list, newComment];
            }
            return list.map(comment => {
              if (comment.id === newComment.parent) {
                if (!comment.replies) {
                  comment.replies = [];
                }
                comment.replies.push(newComment);
              } else {
                if (comment.replies) {
                  comment.replies = addToNestedList(comment.replies, newComment);
                }
              }
              return comment;
            });
          };
          return addToNestedList(prevList, responseData);
        });

        onSubmit(newComment);
        handleRefresh();
      } catch (error) {
        console.error('Error submitting comment: ', error);
        setErrors({ server: 'Failed to submit comment. Please try again.' });
      }
    } else {
      setErrors(newErrors);
    }
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
    setErrors({ ...errors, name: '' });
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setErrors({ ...errors, email: '' });
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
    setErrors({ ...errors, comment: '' });
  };

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
    setErrors({ ...errors, recaptcha: '' });
  };

  const handleReply = (commentId, authorName) => {
    setReplyTo(commentId);
    setReplyToName(authorName);
    document.getElementById('comment').focus();
  };

  const toggleCommentsVisibility = (e) => {
    e.preventDefault();
    setShowComments(!showComments);
    if (!showComments && commentToggleRef.current) {
      commentToggleRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleReset = () => {
    setName('');
    setEmail('');
    setComment('');
    setRecaptchaToken('');
    setErrors({});
    if(!replyTo) {
      setReplyTo(null);
      setReplyToName('');
    }
  };

  const handleRefresh = () => {
    setName('');
    setEmail('');
    setComment('');
    setRecaptchaToken('');
    setErrors({});
    setReplyTo(null);
    setReplyToName('');
  };

  return (
    <div id="comments" className="comment-area">
      <div className='comments-heading'>
        <h2 className="total-comments">
          {commentsList.length} Comments
        </h2>
        {commentsList.length !== 0 && (
          <a href="#" className="hide-commentbox" onClick={toggleCommentsVisibility}>
            {showComments ? 'Hide' : 'Show'}
            <span className={`fa ${showComments ? 'fa-caret-up' : 'fa-caret-down'}`}></span>
          </a>
        )}
      </div>
      {showComments && (
        <ol className="comment-list">
          {commentsList.map(comment => (
            <Comment key={comment.id} comment={comment} onReply={handleReply} />
          ))}
        </ol>
      )}

      <div className="comment-respond">
        <div id="respond" className="comment-respond">
          <div className="comment-form-title">
            <h2>{replyTo ? (
              <>
              Leave a Reply to <span> {replyToName} </span>
              </>
            ) : ('Leave your comment' )}
            </h2>
            <small>
              <a rel="nofollow" id="cancel-comment-reply-link" href={`${postSlug}#respond`} style={{ display: replyTo ? 'inline' : 'none' }} onClick={handleRefresh}>
                Cancel reply
              </a>
            </small>
          </div>
          <form onSubmit={handleSubmit} id="commentform" className="comment-form" noValidate="novalidate">
            <p className="comment-notes">
              <span id="email-notes">Your email address will not be published.</span>
              <span className="required-field-message"> Required fields are marked <span className="required" aria-required="true">*</span></span>
            </p>
            <div className="input-wrapper">
              <div className='sub-input-wrapper'>
                <div className='form-control-wrapper'>
                  <input
                    type="text"
                    id="name"
                    value={name}
                    onChange={handleNameChange}
                    placeholder="Your Name"
                  />
                  <span className="mandatory" id="mandatory_author">*</span>
                </div>
                {errors.name && <span className="error">{errors.name}</span>}
              </div>
              <div className='sub-input-wrapper'>
                <div className='form-control-wrapper'>
                  <input
                    type="text"
                    id="email"
                    value={email}
                    onChange={handleEmailChange}
                    placeholder="Your Email"
                  />
                  <span className="mandatory" id="mandatory_email">*</span>
                </div>
                {errors.email && <span className="error">{errors.email}</span>}
              </div>
            </div>
            <div className="comment-textarea">
              <div className='form-control-wrapper'>
                <textarea
                  id="comment"
                  value={comment}
                  onChange={handleCommentChange}
                  placeholder="Type your comment here..."
                />
                <span className="mandatory" id="mandatory_comment">*</span>
              </div>
              {errors.comment && <span className="error">{errors.comment}</span>}
            </div>
            <div className="recaptcha-wrapper">
              <ReCAPTCHA
                sitekey="6LfoKAApAAAAAPzYZI4lxrAZCXn6aJIxTU8TBJmu"
                onChange={handleRecaptchaChange}
              />
              {errors.recaptcha && <span className="error">{errors.recaptcha}</span>}
            </div>
            <div ref={commentToggleRef} className="comment-actions">
              <button type="submit">Post Comment</button>
              <button className='reset-button' type="button" onClick={handleReset}>Reset</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CommentForm;
