import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Category.css'; // Import CSS file for styling
 
 
// Define toShortFormat function at the top of the file
Date.prototype.toShortFormat = function() {
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const day = this.getDate();
  const monthIndex = this.getMonth();
  const monthName = monthNames[monthIndex];
  const year = this.getFullYear();
  return `${day} ${monthName} ${year}`;
};
 
const Category = () => {
  const [categories, setCategories] = useState([]);
  const [expandedCategory, setExpandedCategory] = useState(null);
  const location = useLocation();
 
  useEffect(() => {
    fetch('https://www.arunnathaniblog.com/wp-json/custom/v1/categories')
      .then(response => response.json())
      .then(data => {
        if (Array.isArray(data)) {
          setCategories(data);
        } else {
          console.error('Fetched data is not an array:', data);
        }
      })
      .catch(error => console.error('Error fetching categories:', error));
  }, []);
 
 
  useEffect(() => {
    // Expand category if a subcategory is active
    const pathParts = location.pathname.split('/');
    if (pathParts[1] === 'category' && pathParts.length > 2) {
      const activeCategory = categories.find(category =>
        category.slug === pathParts[2] ||
        (category.subcategories && category.subcategories.some(subcategory => subcategory.slug === pathParts[3]))
      );
      if (activeCategory) {
        setExpandedCategory(activeCategory.id);
      }
    }
  }, [location.pathname, categories]);
 
  const toggleCategory = categoryId => {
    setExpandedCategory(prevCategoryId => (prevCategoryId === categoryId ? null : categoryId));
  };
 
  return (
    <div className="sidebar">
       <section className="Blogger hidden-xs">
        <a href="/the-science-of-success/">
          <img className="img-responsive mCS_img_loaded" src="https://www.arunnathaniblog.com/wp-content/themes/Arun_Blog/images/arun_profile.jpg" alt="Arun Nathani CEO Cybage Software" />
        </a>
        <div className="ceo-text">
          <div className="BloggerName">ARUN NATHANI</div>
          <div className="BloggerPost">
            <span>CEO,</span> <a className="cyburl" target="_blank" href="https://www.cybage.com">Cybage Software Pvt. Ltd.</a>
          </div>
        </div>
        
      </section>
      <section className="col-sm-12 search-block hidden-xs">
        <form role="search" method="get" action='/' id="searchform">
          <div className="input-group search-box">
            <input className="blog-search form-control" type="text" id="search" name="s" placeholder="Search Blogs" />
            <div className="input-group-btn">
              <button type="submit" className="btn search-icon pull-right"></button>
            </div>
          </div>
        </form>
      </section>
      <section className="col-sm-12 accordionMenu hidden-xs">
        <h4>CATEGORIES</h4>
              <ul className="category-list" id="menu">
                {categories.map(category => (
                 // Check if the category name is 'Style' and skip rendering it
                   category.name === 'Style' ? null : (
                     <li key={category.id} className={expandedCategory === category.id ? 'active' : ''}>
                      <span
                        className={`category-name ${expandedCategory === category.id ? 'expanded' : ''}`}
                        onClick={() => toggleCategory(category.id)}
                      >
                        <Link to={`/category/${category.slug === 'all-2' ? 'all' : category.slug}`}>{category.name}</Link>
                        <span className="expand-icon">
                          {expandedCategory === category.id ? <i className="fa fa-angle-down"></i> : <i className="fa fa-angle-right"></i>}
                        </span>
                   </span>
                    {expandedCategory === category.id && category.subcategories && (
                    <ul className="subcategory-list">
                      {category.subcategories.map(subcategory => (
         
                        (subcategory.posts_count > 0 ? 
                        <li key={subcategory.id} className={`subcategory ${location.pathname.includes(subcategory.slug) ? 'active' : ''}`}>
                        <Link to={`/category/${category.slug === 'all-2' ? 'all' : category.slug}/${subcategory.slug}`}>{subcategory.name}</Link>
                        </li> : null )
  
                       ))}

                    </ul>
                  )}
                </li>
              )
            ))}
          </ul>

      </section>
      <section className="col-sm-12 tagcloud hidden-xs">
  <div><h4>TAG CLOUD</h4>
    <div className="ctc">
      <a href="/tag/organization/" className="ctc-tag tag-link-544" title="13 topics" rel="tag" >organization</a>
      <a href="/tag/managers/" className="ctc-tag tag-link-559" title="3 topics" rel="tag"  >managers</a>
      <a href="/tag/friends/" className="ctc-tag tag-link-560" title="3 topics" rel="tag"  >friends</a>
      <a href="/tag/competitive-world/" className="ctc-tag tag-link-543" title="2 topics" rel="tag" >competitive world</a>
      <a href="/tag/expectations/" className="ctc-tag tag-link-556" title="2 topics" rel="tag" >expectations</a>
      <a href="/tag/approach/" className="ctc-tag tag-link-557" title="2 topics" rel="tag" >approach</a>
      <a href="/tag/lieutenant/" className="ctc-tag tag-link-549" title="1 topics" rel="tag"  >Lieutenant</a>
      <a href="/tag/battle/" className="ctc-tag tag-link-548" title="1 topics" rel="tag"  >battle</a>
      <a href="/tag/bridge/" className="ctc-tag tag-link-547" title="1 topics" rel="tag"  >bridge</a>
      <a href="/tag/soldier/" className="ctc-tag tag-link-546" title="1 topics" rel="tag"  >soldier</a>
      <a href="/tag/world-war-i/" className="ctc-tag tag-link-545" title="1 topics" rel="tag"  >World War-I</a>
      <a href="/tag/professional-war/" className="ctc-tag tag-link-553" title="1 topics" rel="tag"  >professional-war</a>
      <a href="/tag/truth/" className="ctc-tag tag-link-561" title="1 topics" rel="tag"  >truth</a>
      <a href="/tag/personal-relationships/" className="ctc-tag tag-link-562" title="1 topics" rel="tag"  >personal relationships</a>
      <a href="/tag/heart-wrenching/" className="ctc-tag tag-link-550" title="1 topics" rel="tag"  >heart-wrenching</a>
      <a href="/tag/results/" className="ctc-tag tag-link-558" title="1 topics" rel="tag"  >results</a>
      <a href="/tag/line-of-fire/" className="ctc-tag tag-link-555" title="1 topics" rel="tag"  >line of fire</a>
      <a href="/tag/scenario/" className="ctc-tag tag-link-552" title="1 topics" rel="tag"  >scenario</a>
      <a href="/tag/successful-deliveries/" className="ctc-tag tag-link-554" title="1 topics" rel="tag"  >successful deliveries</a>
      <a href="/tag/bridging-of-hearts/" className="ctc-tag tag-link-551" title="1 topics" rel="tag"  >bridging of hearts</a>
    </div>
   </div>
  
  </section>
  <section class="col-sm-12 connectWith hidden-xs">
    <h4>CONNECT WITH CYBAGE</h4>
    <div>
    <a class="cyburl" target="_blank" href="https://www.cybage.com">www.cybage.com</a>
    </div>
    </section>
    
    </div>
  );
};
 
export default Category;
 