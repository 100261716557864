import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Blog from './components/Blog';
import BlogDetail from './components/BlogDetail';
import Home from './pages/Home';
import About from './components/About';
import CategoryBlog from './components/CategoryBlog'; // Import the CategoryBlog component
import MainCategoryBlog from './components/MainCategoryBlog'; // Import the MainCategoryBlog component
import TagBlog from './components/TagBlog';
import Privacy from './components/Privacy';
import ScrollToTop from './components/ScrollToTop';
import CookiePolicyPopup from './components/GDPRCookieConsent';

import './App.css';

const App = () => {
    return (
        <div id="page">
            <Header />
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/category/:category/:subcategory" element={<CategoryBlog />} /> {/* Add this route */}
                <Route path="/category/:category" element={<MainCategoryBlog />} /> {/* Add this route */}
                <Route path="/tag/:tag" element={<TagBlog />} /> {/* Add this route */}
                <Route path="/:slug" element={<BlogDetail />} />
                <Route path="/privacy-policy" element={<Privacy></Privacy>} />
                <Route path="/the-science-of-success/" element={<About />} />
            </Routes>
            <CookiePolicyPopup />

        </div>
    );
};

export default App;
