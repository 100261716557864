// About.js
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';

import './About.css';
// import './Blog.css';
import Footer from './footer';
import facebook from '../images/facebook_32.png';
import linkedin from '../images/linkedin_32.png';
import twitter from '../images/twitter_32.png';
import arun from '../images/arun_profile.jpg';
import Category from './Category';
import CategoryMob from './CategoryMob';
function About() {
	const [isHovering, setIsHovering] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
	const [toggle, setToggle] = useState(true);
  const location = useLocation();
  const currentPath = location.pathname;
  
	const handleToggleCategory = () => {
    console.log("clicked on handlecategory");
		setToggle(!toggle);
	  };
	
	  const handleMouseOver = () => {
		setIsHovering(true);
	  };
	
	  const handleMouseOut = () => {
		setIsHovering(false);
	  };
	
	  const handleResize = () => {
		setIsMobile(window.innerWidth < 768);
		if (window.innerWidth >= 768) {
		  setIsOpen(false);
		}
	  };
	
	  const toggleMenu = () => {
		setIsOpen(!isOpen);
    if(!isOpen === toggle){
      setToggle(!toggle)
    }
	  };
	
	  useEffect(() => {
		window.addEventListener('resize', handleResize);
	
		return () => {
		  window.removeEventListener('resize', handleResize);
		};
	  }, []);

    const handleisOpen = () => {
      setIsOpen(!isOpen);
    };
  return (
    <div className="about">
		<div className='sidebar'>
        <section className="Blogger hidden-xs">
          <a href="/the-science-of-success/">
            <img className="img-responsive mCS_img_loaded" src={arun} alt="Arun Nathani CEO Cybage Software" /></a>
          <div className="ceo-text">
            <div className="BloggerName">ARUN NATHANI</div>
            <div className="BloggerPost">
              <span>CEO,</span> <a className="cyburl" target="_blank" href="https://www.cybage.com">Cybage Software Pvt. Ltd.</a>
            </div>
          </div>
        </section>
        <Category />
      </div>
	  <div className="post-container main-container">
	  	<header className="header fixed">
          <nav className="nav navbar navbar-default">
            <div className="navbar-header">
              <a href="/the-science-of-success/" className={`navbar-brand ${currentPath === '/the-science-of-success/' ? 'active' : ''}`}> <span>THE SCIENCE OF <b>SUCCESS</b></span>
                <span className="txtcolor">Arun Nathani</span>
              </a>
            </div>
            {isMobile && (
              
              <button className="toggle-btn" onClick={toggleMenu} type="button" data-target="#navbarCollapse" data-toggle="collapse" class={` navbar-toggle ${isOpen ? 'active' : ''}`}>
              <span className="sr-only">Toggle navigation</span> <span class="icon-bar"></span> <span class="icon-bar"></span> <span class="icon-bar"></span>
          </button>
            )}
            {!isMobile && (
              <ul className="nav-list desktop-nav" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
              <li className={`nav-item ${currentPath === '/' ? 'active' : ''}`}>
                <Link to="/">Home</Link>
              </li>
              <li className={`nav-item ${currentPath === '/the-science-of-success/' ? 'active' : ''}`}>
                <Link to="/the-science-of-success/">about</Link>
              </li>
            </ul>
            )}
            {isOpen && isMobile && (
              <ul className="nav-list mobile-nav ${isOpen ? 'open' : ''}`}">
                <li className="nav-item">
                  <Link onClick={handleisOpen} to="/">Home</Link>
                </li>
                <li className="nav-item">
                  <Link onClick={handleisOpen} to="/the-science-of-success/">about</Link>
                </li>
               <li className="nav-item category">
                  <Link onClick={handleToggleCategory}>Categories
                  { !isOpen === toggle ? <i class="fa fa-plus-circle" aria-hidden="true"></i> : <i class="fa fa-minus-circle" aria-hidden="true"></i> }
                  </Link>
                </li>
                {toggle && <CategoryMob />}
              </ul>
            )}
          </nav>
          <div id="navbarCollapse" class="navbar-collapse pull-right collapse in" >
            <div class="menu-arun-blog-menu-container"><ul class="nav navbar-nav"><li id="menu-item-1495" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-1495"><a href="/">HOME</a></li>
              <li id="menu-item-1496" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1496"><a href="https://www.arunnathaniblog.com/the-science-of-success/
              ">ABOUT</a></li>
            </ul>
            </div>   
                      {/* <section class="col-sm-12 accordionMenu category-nav hidden-sm hidden-md hidden-lg">
              <a href="#">CATEGORIES <span class="fa fa-plus"></span></a>
            </section> */}
            </div>
           
         	<div className="topbar ">
			  <div className="breadcrumb" id="breadcrumb">
				<ul id="breadcrumbs" className="breadcrumbs">
				  <li className="item-home"><a className="bread-link bread-home" href="/" title="Home">Home</a></li>
				  <li className="separator separator-home"> &nbsp;&nbsp;&gt;&nbsp;&nbsp; </li>
				  <li className="item-current item-cat"><span className="bread-current bread-cat">The Science of Success</span></li>
				  {/* <li className="separator separator-home"> &nbsp;&nbsp;&gt;&nbsp;&nbsp; </li> */}
				</ul>
			  </div>
			</div>
        </header>
        { isMobile && (
              <section className="mobile-Blogger hidden-xs">
                <div className="arun-mobile-imgblk">
                <a href="/the-science-of-success/">
                <img className="img-responsive mCS_img_loaded" src={arun} alt="Arun Nathani CEO Cybage Software" />
              </a>
              </div>
              <div className="ceo-text">
                <div className="mobile-BloggerName BloggerName">ARUN NATHANI</div>
                <div className="mobile-BloggerPost BloggerPost">
                  <span>CEO,</span> <a className="cyburl" target="_blank" href="https://www.cybage.com">Cybage Software Pvt. Ltd.</a>
                </div>
              </div>
            </section>
            )}

      <div className="inner-content">
		
      <div className='post-detail' >
		<h2>The Science of Success</h2>
		<div class="line clearfix">
			<div class="entry-info">
				<div class="col-xs-12 col-md-6"></div>

			</div>
		</div>
			<div id="wpa_content">					
			<p>It is fascinating to track the lives of successful people. Digging <em>secrets of success from their inspiring stories has a nice mystical ring to it. Unfortunately, such unraveled secrets have a short shelf life. Why? Because the aura surrounding the stupendous success of a leader makes it tough to visualize ourselves in their shoes. Perhaps, the most we can extract from the pursuit of larger-than-life heroes is an awe on the <strong>art of success</strong>.</em></p>
<p><em>To draw practical lessons, we need examples from daily lives of commoners. It is easier to relate to the tiny incidents that happen every day all around us. The beauty of this approach is that there is enough chaos in the real world for all of us to have our fair share of random moments. And if these random moments are deciphered correctly, there is a treasure waiting to be discovered. The more we learn to decipher, the more we can correlate success with science. This blog is my humble attempt to share my understanding of the <strong>science of success</strong>.</em></p>
<p><em>The stories are simple; perhaps a few might even leave you with a déjà vu feeling. Many stories are drawn from my experiences; some are improvised anecdotes; a few are pulled from print archives; and the rest are my video escapades.</em></p>
<p><em>The quest for success is a risky business. Not too different from car-racing. If we wish to win a grand prix, there are eight essential learnings that we need to master first, recommended in the following order:</em></p>
<p><em><strong>Purpose.</strong> Know the purpose before turning on the ignition. Why are we racing?</em></p>
<p><em><strong>Skills.</strong> Skills are the fuel. Do we have the capabilities &amp; potential to last the race?</em></p>
<p><em><strong>Attitude.</strong> Attitude is the steering wheel. How good is our control of the direction?</em></p>
<p><em><strong>Luck.</strong> There can be an unexpected breakdown. Are we prepared to crash out?</em></p>
<p><em><strong>Bumps.</strong> The lanes are narrow, crowded &amp; dangerous. Can we maneuver safely?</em></p>
<p><em><strong>Team.</strong> Racing is a team game. Are we building &amp; grooming our pit stop teams?</em></p>
<p><em><strong>Customer.</strong> Customer is sponsor of the race. Are we managing this entity well?</em></p>
<p><em><strong>Leader.</strong> The pole position can be lost. How to continuously maintain the lead?</em></p>
<p><em>Happy racing!</em></p>
	
			</div>				
		</div>
		
    </div>
	<Footer />
	</div>
	</div>
  );
}

export default About;
