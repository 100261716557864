import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link, useLocation } from 'react-router-dom';
import './BlogDetail.css';
import CommentForm from './CommentForm';
import Category from './Category';
import CategoryMob from './CategoryMob';
import LikeButton from './LikeButton'; // Import the LikeButton component
import arun from '../images/arun_profile.jpg';
import MediaPlayer from './MediaPlayer'; // Adjust the import path as needed
import Footer from './footer';
import Loader from './Loader';



function BlogDetail({combinedIdentifier}) {
  const [isLoading, setIsLoading] = useState(false);
  const [post, setPost] = useState({});
  const [postStatus, setPostStatus] = useState({});
  const [adjacentPosts, setAdjacentPosts] = useState({ prev_post: null, next_post: null });

  const { slug } = useParams();
  const [allPosts, setAllPosts] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isHovering, setIsHovering] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [subcategories, setSubcategories] = useState([]);
  const [style, setStyle] = useState([]);
  const [categoryName, setCategory] = useState([]);
  const[category_subcategory, setCategorySubcategory] = useState([]);
  const[styleNames,setStyleNames] = useState([]);
  const [showContent, setShowContent] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const navigate = useNavigate();

  const location = useLocation();
  const currentPath = location.pathname;
  const toggleContent = () => {
    setShowContent(!showContent);
  };
 
  const handleClick = () => {
    setIsActive(!isActive);
  };
  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        // Fetch post details
        const postResponse = await fetch(`https://www.arunnathaniblog.com/wp-json/wp/v2/posts?slug=${slug}`);
        
        if (!postResponse.ok) {
          throw new Error('Failed to fetch post data');
        }
        const postData = await postResponse.json();
        if (postData && postData.length > 0) {
          setPost(postData[0]);
        }

        // Fetch post tags and categories if post ID is available
        if (postData && postData.length > 0 && postData[0].id) {
          const postId = postData[0].id;

          // Fetch post tags
          const tagsResponse = await fetch(`https://www.arunnathaniblog.com/wp-json/wp/v2/tags?post=${postId}&per_page=50`);
          if (!tagsResponse.ok) {
            throw new Error('Failed to fetch tags data');
          }
          const tagsData = await tagsResponse.json();
          const tags = tagsData.map(tag => tag.name);

          // Fetch post categories
          const categoriesResponse = await fetch(`https://www.arunnathaniblog.com/wp-json/wp/v2/categories?post=${postId}`);
          if (!categoriesResponse.ok) {
            throw new Error('Failed to fetch categories data');
          }
          const categoriesData = await categoriesResponse.json();
          const categories = categoriesData.map(category => category.name);
         
          // Filter categoryName based on conditions
          let categoryName = categoriesData.filter(category => category.parent === 0).map(category => category.name);
          if (categoryName.length > 1) {
              categoryName = categoryName.filter(name => !(name.toLowerCase() === 'all' || name.toLowerCase() === 'style'));
          }
         
          // Filter subcategoryfinal based on conditions
          let subcategoryNames = categoriesData.filter(category => category.parent !== 0).map(category => category.name);
          if (subcategoryNames.length > 1) {
              subcategoryNames = subcategoryNames.filter(name => !(name.toLowerCase() === 'all'));
          }
         
          // Filter style based on conditions
          const styleValues = ['Original', 'Video', 'Adapted', 'All'];
          let style = subcategoryNames.filter(cat => styleValues.includes(cat));
          let styleNames = subcategoryNames.filter(cat => styleValues.includes(cat));
          if (style.length > 1) {
              style = style.filter(name => !(name.toLowerCase() === 'all'));
              const hasVideo = style.some(name => name.toLowerCase() === 'video');
              if (hasVideo) {
                 style = style.filter(name => !(name.toLowerCase() === 'video'));
              }
 
          }
     
          const categories_subcategory = categories.filter(cat => !styleValues.includes(cat));
          const subcategoryfinal = subcategoryNames.filter(cat => !styleValues.includes(cat));
         
          setStyle(style);
          setStyleNames(styleNames);
          setCategory(categoryName);
          setSubcategories(subcategoryfinal);
          setCategorySubcategory(categories_subcategory);

          // Update post state with tags and categories
          setPost(prevPost => ({
            ...prevPost,
            tags,
            categories
          }));

         

          // Fetch adjacent posts
          const adjacentResponse = await fetch(`https://www.arunnathaniblog.com/wp-json/custom/v1/adjacent_posts/${postId}`);
          if (adjacentResponse.ok) {
            const adjacentData = await adjacentResponse.json();
            setAdjacentPosts(adjacentData);
          } else {
            console.error('Failed to fetch adjacent posts for post ID:', postId);
          }

          // Update the view count          
          await updateViewCount(postId);


        }

      } catch (error) {
        console.error('Error fetching data: ', error);
      }
      finally{
        setIsLoading(false);
      }
    };

    fetchData();      
  }, [slug]);

   // Function to update the view count using fetch
   const updateViewCount = async (postId) => {
    try {
      const response = await fetch('https://www.arunnathaniblog.com/wp-json/custom/v1/update-view/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ post_id: postId }),
      });
      
      const result = await response.json();
      if (result.success) {
        console.log('View count updated:', result.data.new_count);
      } else {
        console.error('Failed to update view count:', result.message);
      }
    } catch (error) {
      console.error('Error updating view count:', error);
    }
  };

 useEffect(() => {
    const handleScrollToComments = () => {
      const hash = window.location.hash;
      if (hash === '#comments') {
        const commentsSection = document.getElementById('comments');
        if (commentsSection) {
          commentsSection.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };

    // Ensure the scroll happens only after the content is fully loaded
    if (!isLoading && post && Object.keys(post).length > 0) {
      handleScrollToComments();
    }
  }, [isLoading, post]);
  
  const toggleMenu = () => {
   
    setIsOpen(!isOpen);
    if(!isOpen === toggle){
      setToggle(!toggle)
      
    }
    if(!isOpen){
      setIsActive(isActive);
    }
  };
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };
  // Function to handle comment submission
  const handleCommentSubmit = (commentData) => {
    // Add logic to submit the comment
    console.log("Comment submitted:", commentData);
  };
  const handleToggleCategory = () => {
    setToggle(!toggle);
  };
  const findPostIndex = () => {
    return allPosts.findIndex(p => p.slug === slug);
  };
  const handleisOpen = () => {
    setIsOpen(!isOpen);
  };
  const currentIndex = findPostIndex();
  const prevIndex = currentIndex > 0 ? currentIndex - 1 : null;
  const nextIndex = currentIndex < allPosts.length - 1 ? currentIndex + 1 : null;
  const prevPost = prevIndex !== null ? allPosts[prevIndex] : null;
  const nextPost = nextIndex !== null ? allPosts[nextIndex] : null;

  if (!post) {
    return <div>Loading...</div>;
  }

  Date.prototype.toShortFormat = function() {
    const monthNames = ["Jan", "Feb", "Mar", "Apr",
                        "May", "Jun", "Jul", "Aug",
                        "Sep", "Oct", "Nov", "Dec"];
    const day = this.getDate();
    const monthIndex = this.getMonth();
    const monthName = monthNames[monthIndex];
    const year = this.getFullYear();
    return `${day} ${monthName} ${year}`;  
  }
  
  var d = new Date(post.date).toShortFormat();
  // if (isLoading) {
  //   return null; // Render nothing until data is available
  // }
  const handleNavigate = (slug) => {
    navigate(`/${slug}`);
    window.scrollTo(0, 0); // Scroll to top when navigation occurs
  };

  return (
    <div>
      <div className='sidebar'>
        <Category />
      </div>
      <div className="content">
        <header className="header fixed">
          <nav className="nav navbar navbar-default">
            <div className="navbar-header">
            <a href="/the-science-of-success/" className={`navbar-brand ${currentPath === '/the-science-of-success/' ? 'active' : ''}`}> <span>THE SCIENCE OF <b>SUCCESS</b></span>
                <span className="txtcolor">Arun Nathani</span>
              </a>
            </div>
            {isMobile && (
                            <button className="toggle-btn" onClick={toggleMenu} type="button" data-target="#navbarCollapse" data-toggle="collapse" class={` navbar-toggle ${isOpen ? 'active' : ''}`}>

              {/* <button  className={`navbar-toggle toggle-btn ${isActive ? 'active' : ''}`} onClick={toggleMenu} type="button" data-target="#navbarCollapse" data-toggle="collapse" class=""> */}
              <span className="sr-only">Toggle navigation</span> <span class="icon-bar"></span> <span class="icon-bar"></span> <span class="icon-bar"></span>
          </button>
            )}
            {!isMobile && (
              <ul className="nav-list desktop-nav" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
              <li className={`nav-item ${currentPath === '/' ? 'active' : ''}`}>
                <Link to="/">Home</Link>
              </li>
              <li className={`nav-item ${currentPath === '/the-science-of-success/' ? 'active' : ''}`}>
                <Link to="/the-science-of-success/">about</Link>
              </li>
            </ul>
            )}
            {isOpen && isMobile && (
              <ul className="nav-list mobile-nav ${isOpen ? 'open' : ''}`} ">
                <li className="nav-item">
                  <Link onClick={handleisOpen} to="/">Home</Link>
                </li>
                <li className="nav-item">
                  <Link onClick={handleisOpen} to="/the-science-of-success/">about</Link>
                </li>
                    <li className="nav-item category">
                    <Link onClick={handleToggleCategory}>Categories
                  { !isOpen === toggle ? <i class="fa fa-plus-circle" aria-hidden="true"></i> : <i class="fa fa-minus-circle" aria-hidden="true"></i> }
                  </Link>                </li>
                {toggle && <CategoryMob />}
              </ul>
            )}
          </nav>
        
          <div className="topbar ">
            <div className="breadcrumb" id="breadcrumb">
            <ul id="breadcrumbs" className="breadcrumbs">
              <li className="item-home">
                <a className="bread-link bread-home" href="/" title="Home">Home</a>
              </li>
              <li className="separator separator-home"> &nbsp;&nbsp;&gt;&nbsp;&nbsp; </li>

              {post.custom_categories && post.custom_categories.parent_category && (
                <>
                  <li className="item-category">
                    <a
                      className="bread-link bread-home"
                      href={`/category/${String(post.custom_categories.parent_category.name ).toLowerCase().replace(/\s+/g, '-')}`}
                      title={post.custom_categories.parent_category.name}
                    >
                      {post.custom_categories.parent_category.name}
                    </a>
                  </li>
                  <li className="separator separator-home"> &nbsp;&nbsp;&gt;&nbsp;&nbsp; </li>
                </>
              )}

              {post.custom_categories && post.custom_categories.child_category && (
                <>
                  <li className="item-subcategory">
                    <a
                      className="bread-link bread-home"
                      href={`/category/${String(post.custom_categories.parent_category.name).toLowerCase().replace(/\s+/g, '-')}/${String(post.custom_categories.child_category.name).toLowerCase().replace(/\s+/g, '-')}`}
                      title={post.custom_categories.child_category.name}
                    >
                      {post.custom_categories.child_category.name}
                    </a>
                  </li>
                  <li className="separator separator-home"> &nbsp;&nbsp;&gt;&nbsp;&nbsp; </li>
                </>
              )}  

              {post.title && post.title.rendered && (
                <React.Fragment>                
                  <li className="item-current item-67">
                    <span className="bread-current bread-67" dangerouslySetInnerHTML={{ __html: post.title.rendered }} />
                  </li>
                </React.Fragment>
              )}
            </ul>

  
            </div>
          </div>
        </header>
        { isMobile && (
              <section className="mobile-Blogger hidden-xs">
                <div className="arun-mobile-imgblk">
              <a href="/">
                <img loading="lazy" className="img-responsive mCS_img_loaded" src={arun} alt="Arun Nathani CEO Cybage Software" /></a></div>
              <div className="ceo-text">
                <div className="mobile-BloggerName BloggerName">ARUN NATHANI</div>
                <div className="mobile-BloggerPost BloggerPost">
                  <span>CEO,</span> <a className="cyburl" target="_blank" href="https://www.cybage.com">Cybage Software Pvt. Ltd.</a>
                </div>
              </div>
            </section>
            )}
        <div className="inner-content">
       
       
          <div className="post-detail">
          
            <article>
              {post && post.title && <h2 dangerouslySetInnerHTML={{ __html: post.title.rendered }}></h2>}
             
                
              <div className="line clearfix">
                <div className="entry-info">
                  <div className="col-xs-10 entry-meta">
                    <div className="entry-date col-xs-6 col-sm-2">
                      <label>Date: </label><span>{d}</span>
                    </div>
                    {post && post.custom_categories && (
                      <div className="entry-standard entry-cat col-xs-6 col-sm-3">
                        <label className="hidden-xs"> Category: </label>
                        <span>
                          {post.custom_categories.parent_category && (
                            <a href={`/category/${String(post.custom_categories.parent_category.name ).toLowerCase().replace(/\s+/g, '-')}`}>
                              {post.custom_categories.parent_category.name}
                            </a>
                          )}
                          {post.custom_categories.child_category && (
                            <>
                              {', '}
                              <a href={`/category/${String(post.custom_categories.parent_category.name).toLowerCase().replace(/\s+/g, '-')}/${String(post.custom_categories.child_category.name).toLowerCase().replace(/\s+/g, '-')}`}>
                                {post.custom_categories.child_category.name}
                              </a>
                            </>
                          )}
                        </span>
                      </div>
                    )}

                    {post && post.custom_categories && post.custom_categories.style_category && (
                      <div className="entry-standard entry-style col-xs-6 col-sm-2">
                        <label> Style: </label>
                        <span>
                          <a href={`/category/style/${String(post.custom_categories.style_category.name).toLowerCase().replace(/\s+/g, '-')}`}>
                            {post.custom_categories.style_category.name}
                          </a>
                        </span>
                      </div>
                    )}


                    <div className="entry-standard entry-length col-xs-6 col-sm-3">
                      <label> Length: </label> <span title="Reading Rate - 180WPM">{post.length_count ? `${post.length_count} min${post.length_count > 1 ? 's.' : '.'}` : '0 min.'}</span>
                    </div>
                  </div>
                  
                  <div className="col-xs-2 col-sm-2 pdf-download pull-right">
                    <div className="entry-standard download_pdf">
                      <div className="pdfprnt-buttons">
                        <a href={`https://www.arunnathaniblog.com/${post.slug}/?print=pdf`} className="pdfprnt-button pdfprnt-button-pdf" target="_blank">
                          <img loading="lazy" src="https://www.arunnathaniblog.com/wp-content/plugins/pdf-print/images/pdf.png" alt="image_pdf" title="Download PDF" />
                          <span className="pdfprnt-button-title pdfprnt-button-pdf-title">Download</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {
                  post.story_parts != null ? (
                    [4220, 4216, 4218, 4223].includes(post.id) ? (
                      <div className='version'>
                        {Array.isArray(post.story_parts) ? (
                          post.story_parts.map((part, index) => (
                            <div key={index}>
                              <a href={part.story_link}> {`Version-${part.parts_number}`}</a>
                            </div>
                          ))
                        ) : (
                          typeof post.story_parts === 'object' ? (
                            <div>
                              {Object.keys(post.story_parts).map((key, index) => (
                                <div key={index}>
                                  <a href={post.story_parts[key].story_link}>{`Version-${post.story_parts[key].parts_number}`}</a>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div>Invalid story parts format.</div>
                          )
                        )}
                      </div>
                    ) : (
                      <div className='parts'>
                        <label>Parts:</label>
                        {Array.isArray(post.story_parts) ? (
                          post.story_parts.map((part, index) => (
                            <div key={index}>
                              <a href={part.story_link}>{part.parts_number}</a>
                            </div>
                          ))
                        ) : (
                          typeof post.story_parts === 'object' ? (
                            <div>
                              {Object.keys(post.story_parts).map((key, index) => (
                                <div key={index}>
                                  <a href={post.story_parts[key].story_link}>{post.story_parts[key].parts_number}</a>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div>Invalid story parts format.</div>
                          )
                        )}
                      </div>
                    )
                  ) : null
                }
              </div>

              {styleNames.includes('Video') ? (
                  <div>
                    <MediaPlayer postId={post.id} />
                    <div className='toggle-read'><span className=" read-content">If you love to read, here is the above speech in written format -</span>
                  <span  className=" read-arrow" style={{ cursor: 'pointer' }} onClick={toggleContent}>
                      {showContent ? (<>Read <span className="fa fa-caret-down"></span></>) : (<>Read <span className="fa fa-caret-up"></span></>)}
                  </span>
                  </div>
                  {showContent && (
                      <div >
                          <div dangerouslySetInnerHTML={{ __html: post.content && post.content.rendered }} />
                      </div>
                  )}
              </div>
                ) : (
                  <div className='blogs-text-content' dangerouslySetInnerHTML={{ __html: post.content && post.content.rendered }} />
                )}
              <div className="post-views content-post post-4678 entry-meta">
                <span className="post-views-icon dashicons dashicons-chart-bar"></span> 
                <span className="post-views-label">Post Views: </span>&nbsp; 
                <span className="post-views-count"> {post.views_count}</span>
              </div>
              
              <div className="menu-social-links-menu-container social-icons">
                    <ul className="nav-section-new">
                      <li className="nav-item menu-li-soc">
                        <a href="https://www.facebook.com/cybage/" target="_blank">
                          <span className="fa fa-facebook"></span>
                        </a>
                      </li>
                      <li className="nav-item menu-li-soc">
                        <a href="http://twitter.com/cybagesoftware" target="_blank">
                          <span className="fa fa-brands fa-x-twitter"></span>
                        </a>
                      </li>
                      <li className="nav-item menu-li-soc">
                        <a href="https://www.linkedin.com/company/cybage/" target="_blank">
                          <span className="fa fa-linkedin"></span>
                        </a>
                      </li>
                       {/* Like button */}
                       <LikeButton postId={post.id} slug={post.slug} combinedIdentifier={combinedIdentifier} postStatus={postStatus} likes_count= {post.likes_count} />
                      
                    </ul>
                  </div>
                
              {post && post.tags && post.tags.length > 0 && (
                <div className="tags">
                  <label>Tags : </label>
                  {post.tags.map((tag, index) => (
                    <a key={index} href={`/tag/${String(tag).toLowerCase().replace(/\s+/g, '-')}`}> {tag}</a>
                  ))}
                </div>
              )}
              {post && post.categories && (
                <div className="categories">
                  <label>Categories : </label>
                  {[...new Set(post.categories)].filter(category => category !== "All").map((category, index) => (
                    <a key={index} href={`/category/${String(category).toLowerCase().replace(/\s+/g, '-')}`}> {category}</a>
                  ))}
                </div>
              )}
            </article>
            <div className="prev-next-wrapper">
              <div>
              {adjacentPosts.prev_post && (
            //<Link to={`/${adjacentPosts.prev_post.slug}`}>
              <div onClick={() => handleNavigate(adjacentPosts.prev_post.slug)} class="col-xs-6 tex-left pull-left prev" style={{ cursor: 'pointer' }}>
                <span class="fa fa-solid fa-angle-left"></span>
                <span class="prev">{adjacentPosts.prev_post.title.replace(/&amp;/g,'&')}</span>
                </div>
              
            // </Link>
            
            )}
              </div>
              <div>
              {adjacentPosts.next_post && (
              // <Link to={`/${adjacentPosts.next_post.slug}`}>
                <div onClick={() => handleNavigate(adjacentPosts.next_post.slug)} class="col-xs-6 text-right pull-right right" style={{ cursor: 'pointer' }}><span class="next">{adjacentPosts.next_post.title.replace(/&amp;/g,'&')}</span><span class="fa fa-solid fa-angle-right"></span></div>
                
              // </Link>
            )}
              </div>
            </div>
            {post && post.id && (
              <CommentForm postId={post.id} onSubmit={handleCommentSubmit} />
            )}

          </div>
        </div>
        <Footer/>
      </div>
    </div>
  );
}

export default BlogDetail;
