import React, { useState, useEffect } from 'react';

import './GDPRCookieConsent.css'

const GDPRCookieConsent = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [preferences, setPreferences] = useState({
    necessary: true,
    analytics: false,
    marketing: false,
  });

  useEffect(() => {
    const savedPreferences = localStorage.getItem('gdprCookiePreferences');
    if (!savedPreferences) {
      setShowPopup(true);
    } else {
      setPreferences(JSON.parse(savedPreferences));
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('gdprCookiePreferences', JSON.stringify(preferences));
    setShowPopup(false);
    setShowSettings(false);
  };

  const handleSettingsClick = () => {
    setShowSettings(true);
  };

  const handlePreferenceChange = (e) => {
    const { name, checked } = e.target;
    setPreferences((prevPreferences) => ({
      ...prevPreferences,
      [name]: checked,
    }));
  };

  if (!showPopup) return null;

  return (
    <div>
  
      <div style={consentStyle}>
      <div className='cookie_block'>
      <div className='cookie_text'>
        <p>
          We are using cookies to give you the best experience on our website.
        </p>
        <p> 
        You can find out more about which cookies we are using or switch them off in
        <button className='setting_button' onClick={handleSettingsClick} style={linkStyle}> settings</button>.
        </p>
        </div>
        <button className='accept_button' onClick={handleAccept} style={buttonStyle}>Accept</button>
        
      </div>
      </div>
      {showSettings && (
        <div style={modalOverlayStyle}>
          <div style={modalStyle} className='setting_popup'>
            <h3>Cookie Preferences</h3>
            <p>Select which cookies you want to accept:</p>
            <div className='popup_text'>
              <label>
                <input
                  type="checkbox"
                  name="necessary"
                  checked={preferences.necessary}
                  disabled
                />
                Necessary Cookies (Required)
              </label>
            </div>
            <div className='popup_text'>
              <label>
                <input
                  type="checkbox"
                  name="analytics"
                  checked={preferences.analytics}
                  onChange={handlePreferenceChange}
                />
                Analytics Cookies
              </label>
            </div>
            <div className='popup_text'>
              <label>
                <input
                  type="checkbox"
                  name="marketing"
                  checked={preferences.marketing}
                  onChange={handlePreferenceChange}
                />
                Marketing Cookies
              </label>
            </div>
            <div style={modalButtonContainerStyle}>
              <button onClick={handleAccept} style={buttonStyle}>Save Preferences</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const consentStyle = {
  position: 'fixed',
  bottom: 0,
  left: 0,
  width: '100%',
  backgroundColor: '#202020',
  borderTop:'1px solid #fff',
  color: '#fff',
  padding: '15px 20px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  zIndex: 1000,
};

const buttonStyle = {
  backgroundColor: '#0c4da2',
  fontSize:'14px',
  fontWeight:'700',
  color: '#fff',
  border: 'none',
  padding: '8px 30px',
  borderRadius: '30px',
  cursor: 'pointer',
};

const linkStyle = {
  background: 'none',
  border: 'none',
  color: '#007BFF',
  textDecoration: 'underline',
  marginLeft: '5px',
  cursor: 'pointer',
  color:'#fff',
};

const modalOverlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1001,
};

const modalStyle = {
  backgroundColor: '#fff',
  padding: '20px',
  borderRadius: '5px',
  width: '400px',
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
};

const modalButtonContainerStyle = {
  marginTop: '20px',
  textAlign: 'right',
};

export default GDPRCookieConsent;
