// src/pages/Home.js
import React ,{ useRef } from 'react';
import Blog from '../components/Blog'; // Import the Blog component
// import Category from '../components/Category';
import './Home.css';
const Home = () => {
    // const scrollToSection = () => {
    //     const section = document.getElementById("sectionToScrollTo");
    //     if (section) {
    //       section.scrollIntoView({ behavior: 'smooth' });
    //     }
    //   };
    return (
        <div className="blog-home-page">
           
            {/* <Category /> */}
            {/* <Blog scrollToSection={scrollToSection}/> */}
            <Blog/>
        </div>
    );
};

export default Home;

