// src/components/Header.js
import React from 'react';

const Header = () => {
    return (
        <header>
            {/* <h1>My React WordPress Theme</h1> */}
        </header>
    );
};

export default Header;
